import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import NotFound from './pages/NotFoundPage/NotFoundPage';
import PrivateRoutes from './helpers/PrivateRoutes';
import AuthProvider from './context/auth';

const queryClient = new QueryClient();

const theme = createTheme({

  palette: {
    primary: {
      main: '#873e23',
      light: '#492417',
      dark: '#240e06',
    },
    secondary: {
      main: '#657a38',
      light: '#1e6b14',
      dark: '#0e270a',
    },
  },

});

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <div style={styles.container}>
              <Routes>
                <Route path='/login' element={<Login />} />
                <Route element={<PrivateRoutes />}>
                  <Route exact path='/' element={<Home />} />
                  <Route path='/home' element={<Home />} />
                </Route>
                <Route path='*' element={<NotFound />} />
              </Routes>
            </div>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );

};

const styles = {
  container: {
    display: 'flex',
  }
};

export default App;