import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack, Box, CircularProgress } from '@mui/material';

import { SingleSelect, SplitButton, SearchBar, SlimButton, DataTable, Pagination } from '../../../components';

import DownloadData from './DownloadData';
import useFetch from '../../../hooks/useFetch';

import { SERVER_URL } from '../../../config';

const Quilometragem = () => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchedText, setSearchedText] = useState();
  const [selectedSafra, setSelectedSafra] = useState((new Date().getFullYear()).toString());

  const { data, loading } = useFetch(`${SERVER_URL}/kilometer`, 'POST', selectedSafra);

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSelectedPage(1)
  }, [searchedText]);

  const editQuilometragem = (quilometragem) => {
    setSearchParams({ 'content': 'Editar Quilometragem', 'quilometragemId': quilometragem['id_quilometragem'], 'safra': quilometragem['safra'] });
    sessionStorage.setItem('editar_quilometragem', JSON.stringify(quilometragem))
  };

  let rows = [];

  const limitDataPerPage = 10;

  let pagination = 0;

  const quilometragensData = (() => {
    data?.forEach((item) => {

      const quilometragemLinha = {
        id_quilometragem: item['id_quilometragem'],
        data: item['data'],
        equipe: item['equipe'],
        placa: item['placa'],
        motorista: item['motorista'],
        km_inicial: item['km_inicial'],
        km_final: item['km_final'],
        total_percorrido: item['total_percorrido'],
        percurso: item['percurso'],
        editar: <SlimButton handleAction={() => editQuilometragem(item)} key={item + 'button'} />
      }

      rows.push(quilometragemLinha);
    });

    if (searchedText) {

      const searchTerm = searchedText.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

      rows = rows.filter(item =>
        item['id_quilometragem']?.toString().includes(searchTerm) ||
        item['safra']?.toString().includes(searchTerm) ||
        item['data'].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm) ||
        item['equipe'].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm) ||
        item['placa'].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm) ||
        item['motorista'].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm) ||
        item['km_inicial']?.toString().includes(searchTerm) ||
        item['km_final']?.toString().includes(searchTerm) ||
        item['total_percorrido']?.toString().includes(searchTerm) ||
        item['percurso'].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
      );

    };

    pagination = Math.ceil(rows.length / limitDataPerPage);

    if (selectedPage > pagination && rows.length !== 0) setSelectedPage(1);

    const initialRowData = selectedPage * limitDataPerPage;
    const finalRowData = selectedPage * limitDataPerPage - limitDataPerPage;

    rows.sort((itemA, itemB) => Number(itemB['id_quilometragem']) - Number(itemA['id_quilometragem'])); //ordena por data mais recente

    rows = rows.filter((__, index) => (index) < initialRowData && (index) >= finalRowData);

    return rows;

  })();

  const typedText = (data) => setSearchedText(data?.toString());

  const getSelectedSafra = (data) => setSelectedSafra(data);

  return (
    <>
      <Box width='100%' marginBottom='10px' display='flex'>
        <SearchBar typedText={typedText} />
        <SingleSelect getSelectedSafra={getSelectedSafra} />
      </Box>

      {loading && <CircularProgress style={styles.loadingAnimation} />}

      {(!loading && data?.length > 0) && (
        <DataTable
          columns={['ID Quilometragem', 'Data', 'Equipe', 'Placa', 'Motorista', 'Km Inicial', 'Km Final', 'Total Percorrido', 'Percurso']}
          items={['id_quilometragem', 'data', 'equipe', 'placa', 'motorista', 'km_inicial', 'km_final', 'total_percorrido', 'percurso']}
          data={quilometragensData}
          editable
        />
      )}

      {(!loading && data?.length === 0) && <span style={{ fontStyle: 'italic' }}> Sem dados para a safra selecionada </span>}

      <Pagination selectedPage={selectedPage} pagination={pagination} setSelectedPage={setSelectedPage} />

      <Stack>
        <SplitButton safra={data} sheet='Quilometragem' fileName='Quilometragem' downloadData={DownloadData} />
      </Stack>

    </>
  );
};

const styles = {
  loadingAnimation: {
    display: 'flex',
    width: '5%',
    height: '5%',
    alignSelf: 'center',
  },
};

export default Quilometragem;