import { useState, useEffect } from 'react';

import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';

const SingleSelect = ({ getSelectedSafra }) => {
  const currentYear = new Date().getFullYear();
  const [value, setValue] = useState(currentYear);

  useEffect(() => {
    getSelectedSafra(value.toString());
  }, [value, getSelectedSafra]);

  const years = [];

  for (let year = 2023; year <= currentYear; year++) {
    years.push(year);
  }

  years.reverse();

  const handleChange = (event) => setValue(event.target.value);

  return (
    <div>
      <FormControl variant='standard' sx={{ ml: 1, mb: 3, minWidth: 120 }}>
        <InputLabel id='demo-simple-select-standard-label'> Safra </InputLabel>
        <Select
          labelId='demo-simple-select-standard-label'
          id='demo-simple-select-standard'
          value={value}
          onChange={handleChange}
          label='Safra'
        >
          {years.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
          <MenuItem key={'todas'} value={'all'}>Todas</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

};

export default SingleSelect;