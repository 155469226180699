import { Stack, Pagination as MuiPagination } from '@mui/material';

const Pagination = ({ selectedPage, pagination, setSelectedPage }) => {
  return (
    <Stack spacing={2} sx={{ display: 'flex', alignSelf: 'center', mt: '30px' }}>
      <MuiPagination
        color='primary'
        page={parseInt(selectedPage)}
        hidePrevButton
        hideNextButton
        count={pagination}
        variant='outlined'
        shape='rounded'
        onClick={(e) => {
          const page = e.target.textContent;
          if (!isNaN(page)) setSelectedPage(page);
        }}
      />
    </Stack>
  );
};

export default Pagination;