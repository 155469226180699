import { useState, useEffect } from 'react';
import { Box, Stack, CircularProgress } from '@mui/material';

import { SingleSelect, Pagination, SplitButton, SearchBar, DataTable } from '../../../components';

import DownloadData from './DownloadData';

import useFetch from '../../../hooks/useFetch';

import { SERVER_URL } from '../../../config';	

const Observacoes = () => {

  const [selectedPage, setSelectedPage] = useState(1);
  const [searchedText, setSearchedText] = useState();
  const [selectedSafra, setSelectedSafra] = useState((new Date().getFullYear()).toString());

  const { data, loading } = useFetch(`${SERVER_URL}/observation`, 'POST', selectedSafra);

  useEffect(() => {
    setSelectedPage(1)
  }, [searchedText]);

  let rows = [];

  const limitDataPerPage = 10;

  let pagination = 0;

  const observacoesData = (() => {

    data?.forEach((item) => {

      const observacaoLinha = {
        id_observacao: item['id_observacao'],
        data: item['data'],
        motorista: item['motorista'],
        operador: item['operador'],
        trecho: item['trecho'],
        latitude: item['latitude'],
        longitude: item['longitude'],
        altitude: item['altitude'],
        direcao: item['direcao'],
        cultura: item['cultura'] || item['cultura_outra'],
        lado: item['lado'],
      };

      rows.push(observacaoLinha);
    });

    if (searchedText) {

      const searchTerm = searchedText.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

      rows = rows.filter(item =>
        item['id_observacao']?.toString().includes(searchTerm)
        || item.safra?.toString().includes(searchTerm)
        || item.data.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
        || item.motorista.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
        || item.operador.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
        || item.trecho.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
        || item.latitude?.toString().includes(searchTerm)
        || item.longitude?.toString().includes(searchTerm)
        || item.altitude?.toString().includes(searchTerm)
        || item.direcao?.toString().includes(searchTerm)
        || item.cultura.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
        || item.lado.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
      );

    }

    pagination = Math.ceil(rows.length / limitDataPerPage);

    if (selectedPage > pagination && rows.length !== 0) setSelectedPage(1);

    const initialRowData = selectedPage * limitDataPerPage;
    const finalRowData = selectedPage * limitDataPerPage - limitDataPerPage;

    rows.sort((itemA, itemB) => Number(itemB['id_observacao']) - Number(itemA['id_observacao'])); //ordena por data mais recente

    rows = rows.filter((__, index) => (index) < initialRowData && (index) >= finalRowData);

    return rows;

  })();

  const typedText = (data) => setSearchedText(data?.toString());

  const getSelectedSafra = (data) => setSelectedSafra(data);

  return (
    <>

      <Box width={'100%'} marginBottom={'10px'} style={{ display: 'flex' }}>
        <SearchBar typedText={typedText} />
        <SingleSelect getSelectedSafra={getSelectedSafra} />
      </Box>

      {loading && <CircularProgress style={styles.loadingAnimation} />}

      {(!loading && data?.length > 0) && (
        <DataTable
          columns={['Id Observacao', 'Data', 'Motorista', 'Operador', 'Trecho', 'Latitude', 'Longitude', 'Altitude', 'Direção', 'Cultura', 'Lado']}
          items={['id_observacao', 'data', 'motorista', 'operador', 'trecho', 'latitude', 'longitude', 'altitude', 'direcao', 'cultura', 'lado']}
          data={observacoesData}
        />
      )}

      {(!loading && data?.length === 0) && <span style={{ fontStyle: 'italic' }}> Sem dados para a safra selecionada </span>}

      <Pagination selectedPage={selectedPage} pagination={pagination} setSelectedPage={setSelectedPage} />

      <Stack>
        <SplitButton safra={data} sheet='Observações' fileName='Observações' downloadData={DownloadData} />
      </Stack>

    </>
  );
};

const styles = {
  loadingAnimation: {
    display: 'flex',
    width: '5%',
    height: '5%',
    alignSelf: 'center',
  },
};

export default Observacoes;