import { useContext, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { AuthContext } from '../context/auth';
import useFetch from '../hooks/useFetch';
import { idRoleAdmin, SERVER_URL } from '../config';

const PrivateRoute = () => {

  const url = `${SERVER_URL}/auth`;
  const { status, loading, data } = useFetch(url, 'POST');

  const { setIsAdmin } = useContext(AuthContext);

  useEffect(() => {
    if (!loading) {
      const idRole = data?.role?.id_role;
      const isAdmin = idRole === idRoleAdmin;

      setIsAdmin(isAdmin);
    };
 }, [loading, data, setIsAdmin]);

  if (loading) return (
    <Box style={styles.loadingContainer}>
      <CircularProgress size={100} />
    </Box>
  );

  return (
    <>
      {status === 'success' ? <Outlet /> : <Navigate to='login' />}
    </>
  )

};

const styles = {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh'
  }
};

export default PrivateRoute;