import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const useFetch = (URL, method = 'GET', safra) => {
  const navigate = useNavigate();

  const fetchData = async () => {
    const options = {
      method,
      body: safra ? JSON.stringify({ safra }) : undefined,
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include',
    };

    const response = await fetch(URL, options);

    if (!response.ok) {
      if (response.status === 401 || response.status === 403) navigate('/login');
      
      throw new Error(`HTTP error! status: ${response.status}`);
    };

    const data = await response.json();

    return { data, status: response.status };
  };

  const { 
    data: queryData, 
    isLoading, 
    error, 
    status
  } = useQuery({
    queryKey: [URL, method, JSON.stringify(safra)],
    queryFn: fetchData,
    retry: (failureCount, error) => {
      if (error instanceof Error && (error.message.includes('401') || error.message.includes('403'))) return false;
      
      return failureCount < 3;
    },

    staleTime: 1 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  return { 
    data: queryData?.data, 
    loading: isLoading, 
    error: error, 
    status: status 
  };
};

export default useFetch;