import writeXlsxFile from 'write-excel-file';

const DownloadData = async (selectedIndex, safra, years, fileName) => {
    const selectedSafra = years[selectedIndex];

    safra = safra.filter((item) => item.safra === selectedSafra.toString());

    const objects = [];

    safra.forEach((item) => {
        const idObservacao = parseInt(item.id_observacao);
        const data = item.data;
        const motorista = item.motorista;
        const operador = item.operador;
        const trecho = item.trecho;
        const latitude = item.latitude;
        const longitude = item.longitude;
        const altitude = item.altitude;
        const direcao = item.direcao;
        const cultura = item.cultura || item.cultura_outra;
        const lado = item.lado;

        objects.push({
            idObservacao, data, motorista, operador, trecho, latitude, longitude, altitude, direcao, cultura, lado
        })

    });

    const schema = [
        { column: 'ID Observação', align: 'center', type: Number, width: 22, value: objects => objects.idObservacao },
        { column: 'Data', align: 'center', width: 22, value: objects => objects.data },
        { column: 'Motorista', align: 'center', width: 22, value: objects => objects.motorista },
        { column: 'Operador', align: 'center', width: 22, value: objects => objects.operador },
        { column: 'Trecho', align: 'center', width: 22, value: objects => objects.trecho },
        { column: 'Latitude', align: 'center', width: 22, value: objects => objects.latitude },
        { column: 'Longitude', align: 'center', width: 22, value: objects => objects.longitude },
        { column: 'Altitude', align: 'center', width: 22, value: objects => objects.altitude },
        { column: 'Direção', align: 'center', width: 22, value: objects => objects.direcao },
        { column: 'Cultura', align: 'center', width: 22, value: objects => objects.cultura },
        { column: 'Lado', align: 'center', width: 22, value: objects => objects.lado },
    ];

    await writeXlsxFile(objects, {
        schema,
        fileName: `${fileName}_${selectedSafra}.xlsx`,
        sheet: selectedSafra, 
    });

};

export default DownloadData;