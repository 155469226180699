import writeXlsxFile from 'write-excel-file';

const DownloadData = async (selectedIndex, safra, years, fileName) => {
    const selectedSafra = years[selectedIndex]

    safra = safra.filter((item) => item.safra === selectedSafra.toString());

    const module = await import(`./versoes/versao${selectedSafra}`);

    const { objects , schema } = module.default(safra);

    await writeXlsxFile(objects, {
        schema,
        fileName: `${fileName}_${selectedSafra}.xlsx`,
        sheet: selectedSafra, 
    });
};

export default DownloadData;