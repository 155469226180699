import writeXlsxFile from 'write-excel-file';

const DownloadData = async (selectedIndex, safra, years, fileName) => {
    const selectedSafra = years[selectedIndex];

    safra = safra.filter((item) => item.safra === selectedSafra.toString());

    const objects = [];

    safra.forEach((item) => {
        const idQuilometragem = parseInt(item.id_quilometragem);
        const data = item.data;
        const equipe = item.equipe;
        const placa = item.placa;
        const motorista = item.motorista;
        const km_inicial = parseInt(item.km_inicial);
        const km_final = parseInt(item.km_final) || null;
        const total_percorrido = parseInt(item.total_percorrido) || null;
        const percurso = item.percurso;

        objects.push({
            idQuilometragem, data, equipe, placa, motorista, km_inicial, km_final, total_percorrido, percurso
        })
    });

    const schema = [
        { column: 'ID Quilometragem', align: 'center', type: Number, width: 22, value: objects => objects.idQuilometragem },
        { column: 'Data', align: 'center', width: 22, value: objects => objects.data },
        { column: 'Equipe', align: 'center', width: 22, value: objects => objects.equipe },
        { column: 'Placa', align: 'center', width: 22, value: objects => objects.placa },
        { column: 'Motorista', align: 'center', width: 22, value: objects => objects.motorista },
        { column: 'Km Inicial', align: 'center', type: Number, width: 22, value: objects => objects.km_inicial },
        { column: 'Km Final', align: 'center', type: Number, width: 22, value: objects => objects.km_final },
        { column: 'Total Percorrido', align: 'center', type: Number, width: 22, value: objects => objects.total_percorrido },
        { column: 'Percurso', align: 'center', width: 22, value: objects => objects.percurso },
    ];

    await writeXlsxFile(objects, {
        schema,
        fileName: `${fileName}_${selectedSafra}.xlsx`,
        sheet: selectedSafra, 
    });

};

export default DownloadData;