import { useContext } from 'react';
import { InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import { AuthContext } from '../../context/auth';

const SelectField = ({ dataList, label, name, valueSelect, options, width }) => {
  const { isAdmin } = useContext(AuthContext);

  return (
    <fieldset disabled>
      <FormControl sx={{ mt: 2, mr: 1, minWidth: 120 }}>

        <InputLabel id={name}>{label}</InputLabel>
        <Select
          sx={{ width: width }}
          labelId={name}
          defaultValue={valueSelect}
          label={label}
          name={name}
          open={isAdmin ? undefined : false}
          onChange={(e) => dataList[e.target.name] = e.target.value}
        >
          {options.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
        </Select>

      </FormControl>
    </fieldset>
  );
};

export default SelectField;